import './App.css';

import {BrowserRouter, Routes, Route, useRoutes} from 'react-router-dom';
import { UserProvider } from './libs/context';

import Home from './pages/public/home';

// auth
import Login from './pages/auth/login';
import EmailLogin from './pages/auth/email';
import PhoneLogin from './pages/auth/phone';
import Authorize from './pages/auth/authorize';
import Bind from './pages/auth/bind';
import Reset from './pages/auth/reset';
import ForgotPassword from './pages/auth/forgot';

// portal
import Portal from './Portal';
import NotFound from './pages/user/notFound';
import Maintenance from "./pages/public/maintenance";

import AccountList from './pages/user/account/list';
import AccountAdd from './pages/user/account/add';
import AccountBasic from './pages/user/account/basic';

import TransactionList from './pages/user/transaction/list';
import TransactionDetail from './pages/user/transaction/detail';
import TransactionWithdraw from './pages/user/transaction/withdraw';

import Dashboard from './pages/user/dashboard';
import Setting from './pages/user/setting/index';
import SettingPassword from './pages/user/setting/password';

const App = () => {
  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PhoneLogin />}/>
          <Route path="/login" element={<PhoneLogin />}/>
          <Route path="/login/email" element={<EmailLogin />}/>
          <Route path="/login/phone" element={<PhoneLogin />}/>
          <Route path="/authorize" element={<Authorize />}/>
          <Route path="/bind" element={<Bind />}/>
          {/* <Route path="/reset/:code" element={<Reset />}/> */}
          {/* <Route path="/password/forget" element={<ForgotPassword />}/> */}

          <Route path="/maintenance" element={<Maintenance />}/>

          <Route path="portal" element={<Portal />}>

            <Route index element={<Dashboard />}/>

            <Route path="account" element={<AccountList/>}/>
            <Route path="account/add" element={<AccountAdd/>}/>
            <Route path="account/basic" element={<AccountBasic />}/>
            

            <Route path="transaction" element={<TransactionList/>}/>
            <Route path="transaction/detail/:id" element={<TransactionDetail/>}/>
            <Route path="transaction/withdraw" element={<TransactionWithdraw/>}/>
            
            <Route path="setting" element={<Setting/>}/>
            <Route path="setting/password" element={<SettingPassword />}/>
      
            <Route path="*" element={<NotFound />}/>
          </Route>
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </BrowserRouter>
    </UserProvider>
  )
}

export default App;

