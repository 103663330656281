
export const getAuthToken = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  if(!auth){
    return null;
  }

  const time = new Date()/1000;
  if(time > auth.expired){
    return null;
  }
  return auth.token;
}

export const isLogin = () => {
  return getAuthToken() != null;
}

export const setAuthToken = (token, expired) => {
  const auth = {token, expired}
  localStorage.setItem("auth", JSON.stringify(auth));
}

export const removeAuthtoken = () => {
  localStorage.removeItem("auth");
}

export const getLoginInfo = () => {
  const info = JSON.parse(localStorage.getItem("login"));
  if(!info){
    return {};
  }
  return info;
}

export const setLoginInfo = (info) => {
  localStorage.setItem("login", JSON.stringify(info));
}
