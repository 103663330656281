import {useEffect, useState,useContext} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/auth';
import { Errors, Successes } from '../../components/common/msg';

import { post } from '../../libs/api';
import UserContext from "../../libs/context";
import { EmailField, TextField, PasswordField, SelectField, DateField, useValidation } from '../../components/form';
import useInterval from '../../libs/useInterval';
import { getLoginInfo, setLoginInfo } from '../../libs/auth';
import { sleep } from '../../libs/util';
import { Toast } from 'react-weui';

function Page() {
  const TAB_CHECK = 0;
  const TAB_REGISTER = 3;
  const TAB_PASSWORD = 2;
  const TAB_CODE = 1;

  const [loading, setLoading] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);
  const [areaCode, setAreaCode] = useState('');
  const [phone, setPhone] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [tab, setTab] = useState(0);
  const [errors, setErrors] = useState([]);
  const [successes, setSuccesses] = useState([]);
  const [notices, setNotices] = useState([]);

  const navigate = useNavigate();
  const {login, app} = useContext(UserContext);
  
  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const [isCountdown, setIsCountdown] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [sendText, setSendText] = useState('Send Code');

  const [showLoginSuccess, setShowLoginSuccess] = useState(false);

  const handlePhoneCheck = async(e) => {
    if(!isValid('areaCode', 'phone')){
      setShowError(true);
      return;
    }

    setErrors([]);
    setLoading(true);
    
    // save info
    const info = getLoginInfo();
    info.areaCode = areaCode;
    info.phone = phone;
    setLoginInfo(info);

    // call backend api to check phone
    const data = await post('/auth/phone/check', {phone, area_code: areaCode}, false);
    console.log(data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else if(data?.data) {
      setTab(TAB_PASSWORD);
    }
    else {
      setTab(TAB_REGISTER);
    }
    setLoading(false);
  }

  const handleRegister = async(e) => {
    if(!isValid(['newPassword', 'confirmPassword'])){
      setShowError(true);
      return;
    }

    if(newPassword != confirmPassword){
      setErrors(["The two passwords do not match."]);
      return;
    }

    setErrors([]);
    setLoading(true);
    // call backend api to register phone
    const data = await post('/auth/phone/register', {phone, area_code: areaCode, password: newPassword}, false);
    console.log(data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else {
      setTab(TAB_CODE);
    }
    setLoading(false);
  }

  const handleSendCode = async(e) => {
    if(isCountdown){
      return;
    }

    setErrors([]);
    setLoading(true);
    // call backend api to send phone code
    const data = await post('/auth/phone/code', {phone, area_code: areaCode}, false);
    // const data = {status: 200};
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else{
      setSuccesses(['Verification code sent']);
      setCountdown(60);
      setIsCountdown(true);
    }
    setLoading(false);
  }

  const handleLogin = async(type) => {
    console.log('handle login', type);
    if(!isValid(type)){
      setShowError(true);
      return;
    }

    setErrors([]);
    setLoading(true);
    // login with verification code
    const data = await post('/auth/phone/' + type + '/login', {phone, area_code: areaCode, code, password}, false);
    console.log(data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else if(!data?.data?.token){
      // verify phone
      setTab(TAB_CODE);
    }
    else{
      const expired = (new Date()/1000) + 86400;
      await login(data?.data.token, expired);
      setShowLoginSuccess(true);
      setTimeout(() => {
        if(app){        
          navigate("/authorize");
        }
        else{
          navigate("/portal");
        }
      }, 1000);
    }
    setLoading(false);
  }
  
  const loadCountries = async () => {
    setCountryOptions([
      {value: '60', label: 'Malaysia (+60)'}, 
      {value: '84', label: 'Vietname (+84)'}, 
      {value: '86', label: 'China (+86)'}
    ])
  }

  useEffect(() => {
    setLoading(false);
    setErrors([]);
    setSuccesses([]);
  }, [tab]);

  useEffect(() => {
    loadCountries();
    const info = getLoginInfo();
    setAreaCode(info?.areaCode);
    setPhone(info?.phone);
  }, []);

  useInterval(() => {
    if (!isCountdown) {
      return;
    }
    if (countdown == 0) {  
      setIsCountdown(false);
      setSendText('Send Code');
      return;
    }
    setSendText('' + countdown + 's');
    setCountdown(countdown - 1);
  }, 1000);

  return (
    <Layout notices={notices}>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <Errors errors={errors}/>
        </div>
      </div>

      {loading ? (
          <div className="loader-container text-center">
            <ClipLoader color={'#1f2b3a'} size={50} />
              <p>Loading, please wait a moment ...</p>
          </div>
      ) : (
        <>
        {tab == TAB_CHECK && (
          <div className="is-alter">
            <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="username">Country <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <SelectField className="form-control form-control-lg" placeholder="Please select a country"
                    required={true} showError={showError} onValidate={(valid) => setFieldValid('areaCode', valid)}
                    options={countryOptions}
                    value={areaCode}
                    onChange={(e) => setAreaCode(e.target.value)}
                  />   
                </div>
            </div>
            
            <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="username">Phone Number <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <TextField className="form-control form-control-lg" placeholder="Enter your phone number"
                    value={phone} required={true} showError={showError}
                    onValidate={(valid) => setFieldValid('phone', valid)}
                    onChange={(e) => setPhone(e.target.value)}/>
                </div>
            </div>

            <div className="form-group">
              <button type="button" className="btn btn-lg btn-primary btn-block" onClick={handlePhoneCheck}>Continue</button>
            </div> 
          </div>
        )}

        {tab == TAB_CODE && (
          <div className="is-alter">
            <div className="mb-2">Please check your phone for the verification code.</div>
            <Successes msgs={successes}/>
            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label" htmlFor="username">Verification Code <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <TextField className="form-control form-control-lg" placeholder="Enter verification code"
                    value={code} required={true} showError={showError}
                    onValidate={(valid) => setFieldValid('code', valid)}
                    onChange={(e) => setCode(e.target.value)}/>
                  <div className="mt-1" ><a href="#" onClick={handleSendCode}>{sendText}</a></div>
                </div>
            </div>
            
            <div className="form-group">
              <button type="button" className="btn btn-lg btn-primary btn-block" onClick={(e) => handleLogin('code')}>Login</button>
            </div>
            <div className="form-note-s2 text-center">
              <a href="#" onClick={() => setTab(TAB_PASSWORD)} ><strong>Login using password</strong></a>
            </div>  
          </div>
        )}

        {tab == TAB_PASSWORD && (
          <div className="is-alter">
            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label" htmlFor="username">Password <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <PasswordField className="form-control form-control-lg" placeholder="Enter password"
                    value={password} required={true} showError={showError}
                    onValidate={(valid) => setFieldValid('password', valid)}
                    onChange={(e) => setPassword(e.target.value)}/>
                </div>
            </div>
            
            <div className="form-group">
              <button type="button" className="btn btn-lg btn-primary btn-block" onClick={(e) => handleLogin('password')}>Login</button>
            </div>
            <div className="form-note-s2 text-center">
              <a href="#" onClick={() => setTab(TAB_CODE)} ><strong>Login using phone verification code</strong></a>
            </div>  
          </div>
        )}

        {tab == TAB_REGISTER && (
          <div className="is-alter">
            <div className="mb-1">Welcome {phone}!</div>
            <div className="mb-2">Enter your password to create a user account.</div>

            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label" htmlFor="username">Create Password <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <PasswordField className="form-control form-control-lg" placeholder="Enter password"
                    value={newPassword} required={true} showError={showError} minLength={8}
                    onValidate={(valid) => setFieldValid('newPassword', valid)}
                    onChange={(e) => setNewPassword(e.target.value)}/>
                </div>
            </div>
            
            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label" htmlFor="username">Confirm Password <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <PasswordField className="form-control form-control-lg" placeholder="Enter password again"
                    value={confirmPassword} required={true} showError={showError} minLength={8}
                    onValidate={(valid) => setFieldValid('confirmPassword', valid)}
                    onChange={(e) => setConfirmPassword(e.target.value)}/>
                </div>
            </div>

            <div className="form-group">
              <button type="button" className="btn btn-lg btn-primary btn-block" onClick={handleRegister}>Create</button>
            </div>
          </div>
        )}

        {tab != TAB_CHECK && (
        <div className="form-note-s2 text-center pt-2">
          <a href="#" onClick={(e) => setTab(TAB_CHECK)}><strong>Return to login</strong></a>
        </div>   
        )}

        <Toast icon="success-no-circle" show={showLoginSuccess}>Login Success</Toast>
        
        </>
      )}
    </Layout>
  )
}

export default Page;
