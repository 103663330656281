import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserContext from '../../../libs/context';
import { post } from '../../../libs/api';
import { EmailField, TextField, PasswordField, SelectField, DateField, useValidation } from '../../../components/form';
import { Errors } from '../../../components/common/msg';

function Page(props) {

  const [loading, setLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState();
  const [bankOptions, setBankOptions] = useState();
  const [showBanks, setShowBanks] = useState(false);
  const [isTargetOrg, setIsTargetOrg] = useState(false);

  const [countryCode, setCountryCode] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [name, setName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [documentNo, setDocumentNo] = useState("");
  const [errors, setErrors] = useState([]);

  const [password, setPassword] = useState('');

  const {currentUser} = useContext(UserContext);
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();
  const [tab, setTab] = useState(1);


  const loadCountries = async () => {
    setCountryOptions([
      {value: 'MY', label: 'Malaysia'},
      {value: 'VN', label: 'Vietname'},
    ])
  }

  const loadBanks = async () => {
    if(countryCode){
      const data = await post('/bank/list', {country_code: countryCode});
      if(data?.status == 200){
        let options = [];
        let show = false;
        for(let item of data.data){
          options.push({value: item.bank_code, label: item.name})
          if(item.bank_code != '/'){
            show = true;
          }
        }
        setShowBanks(true);
        setBankOptions(options);
      }
    }
    else{
      setBankOptions([]);
    }
  }

  useEffect(() => {
    setIsTargetOrg(countryCode == 'VN');
    loadBanks();
  }, [countryCode])

  useEffect(() => {
    console.log('currentUser', currentUser, currentUser?.level == 0);
    if(currentUser?.level == 0){
      // need to add basic info
      navigate('/portal/account/basic');
    }
    else{
      loadCountries();
    }
  }, [])

  const handleAdd = async(e) => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    setTab(2);
    return;
  }

  const handleSubmit = async() => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    if(password == null || password.length == 0){
      setErrors(['Please enter your login password.']);
      return;
    }

    setLoading(true);

    let postTargetOrg = isTargetOrg ? bankCode : null;
    let postBankCode = isTargetOrg ? null : bankCode;

    // post to backend
    const input = {
      name: name, 
      country_code: countryCode, 
      target_org: postTargetOrg,
      bank_code: postBankCode,
      account_no: accountNo, 
      document_no: documentNo,
      payment_method:'payermax',
      password: password
    };
    const data = await post('/account/add', input);
    console.log('input', input, data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);

      if(data?.status == 421){
        // invalid password
      }
      else{
        // invalid input
        setTab(1);
      }
    }
    else{
      // upon success
      navigate('/portal/account');
    }

    setLoading(false);
  }

  return (
    <Layout menu="dashboard">   
      <div className="nk-block">
        <Errors errors={errors}/>

        {tab == 1 && (
        <>
        <div className="row">
          <div className="col-md-12">
            Please fill in the correct information, otherwise your withdrawal will be affected.
          </div>
        </div>
        
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="first-name">Country <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <SelectField className="form-control form-control-lg" placeholder="Please select recipient country"
                  required={true} showError={showError} onValidate={(valid) => setFieldValid('countryCode', valid)}
                  options={countryOptions}
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                />   
              </div>
            </div>

            {showBanks && (
            <div className="form-group">
              <label className="form-label" htmlFor="first-name">Bank <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <SelectField className="form-control form-control-lg" placeholder="Please select recipient bank"
                    required={showBanks} showError={showError} onValidate={(valid) => setFieldValid('bankCode', valid)}
                    options={bankOptions}
                    value={bankCode}
                    onChange={(e) => setBankCode(e.target.value)}
                  /> 
              </div>
            </div>
            )}

            <div className="form-group">
              <label className="form-label" htmlFor="first-name">Full Name <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <TextField className="form-control form-control-lg" placeholder="Enter bank account full name"
                            value={name} required={true} showError={showError} test={false} 
                            onValidate={(valid) => setFieldValid('name', valid)}
                            onChange={(e) => setName(e.target.value)}/>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="first-name">Account Number <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <TextField className="form-control form-control-lg" placeholder="Enter account number"
                            value={accountNo} required={true} showError={showError} test={false} 
                            onValidate={(valid) => setFieldValid('accountNo', valid)}
                            onChange={(e) => setAccountNo(e.target.value)}/>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="first-name">ID Number <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <TextField className="form-control form-control-lg" placeholder="Enter your ID number"
                            value={documentNo} required={true} showError={showError} test={false} 
                            onValidate={(valid) => setFieldValid('documentNo', valid)}
                            onChange={(e) => setDocumentNo(e.target.value)}/>
              </div>
            </div>

          </div>
        </div>

        <div className="row ml-2 mr-2 mt-4">
          <button className="btn btn-lg btn-primary btn-block"
            onClick={handleAdd}>Next</button>
        </div>
        </>
        )}

        {tab == 2 && (
          <>
          <div className="row">
            <div className="col-md-12">
              Please confirm adding the account by entering your login password.
            </div>
          </div>

          <div className="form-group mt-2">
            <div className="form-control-wrap">
              <PasswordField className="form-control form-control-lg" placeholder="Enter your login password"
                          value={password} required={true} showError={showError} test={false} 
                          onChange={(e) => setPassword(e.target.value)}/>
            </div>
          </div>

          <div className="row ml-2 mr-2 mt-4">
            <button className="btn btn-lg btn-primary btn-block"
              onClick={handleSubmit}>Submit</button>
          </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default Page;
