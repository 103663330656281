import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserContext from '../../../libs/context';
import { post } from '../../../libs/api';
import { EmailField, TextField, PasswordField, SelectField, DateField, useValidation } from '../../../components/form';
import { Errors } from '../../../components/common/msg';

function Page(props) {

  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const [errors, setErrors] = useState([]);

  const {currentUser} = useContext(UserContext);
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const handleSubmit = async(e) => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    if(newPassword != newPassword2){
      setErrors(['The two new passwords do not match.']);
      return;
    }

    setErrors([]);
    setLoading(true);

    // post to backend
    const input = {
      old_password: oldPassword, 
      new_password: newPassword,
    };
    const data = await post('/user/password/change', input);
    console.log('input', input, data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else{
      // upon success
      navigate('/portal/setting');
    }

    setLoading(false);
  }

  return (
    <Layout menu="dashboard">   
      <div className="nk-block">

        <div className="row">
          <div className="col-md-12">
            <Errors errors={errors}/>
          </div>
        </div>
        
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="old-password">Current Password <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <PasswordField className="form-control form-control-lg" placeholder="Enter current password"
                            value={oldPassword} required={true} showError={showError} test={false} minLength={8}
                            onValidate={(valid) => setFieldValid('oldPassword', valid)}
                            onChange={(e) => setOldPassword(e.target.value)}/>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="first-name">New Password <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <PasswordField className="form-control form-control-lg" placeholder="Enter new password"
                            value={newPassword} required={true} showError={showError} test={false} minLength={8}
                            onValidate={(valid) => setFieldValid('newPassword', valid)}
                            onChange={(e) => setNewPassword(e.target.value)}/>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="first-name">Confirm Password <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <PasswordField className="form-control form-control-lg" placeholder="Enter new password again"
                            value={newPassword2} required={true} showError={showError} test={false} minLength={8} 
                            onValidate={(valid) => setFieldValid('newPassword2', valid)}
                            onChange={(e) => setNewPassword2(e.target.value)}/>
              </div>
            </div>

          </div>
        </div>

        <div className="row ml-2 mr-2 mt-4">
          <button className="btn btn-lg btn-primary btn-block"
            onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </Layout>
  )
}

export default Page;
