import {post} from './api';

let user = null;
export const getUser = async() => {
  if(user != null){
    return user;
  }

  const data = await post('/user/get');
  if(data?.status == 200){
    user = data.data;
  }
  return user;
}

export const viewUser = () => {
  return user;
}
