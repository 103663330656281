import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserContext from '../../../libs/context';
import { post } from '../../../libs/api';
import { showDateTime, showPrice } from '../../../libs/util';

function Page(props) {

  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState();

  const {currentUser} = useContext(UserContext);
  const navigate = useNavigate();

  const loadTransactions = async () => {
    const data = await post('/transaction/list');
    if(data?.status == 200){
      setTransactions(data.data);
    }
    // setTransactions([
    //   {id: 1, title: 'Withdrawal', amount: 100, created_at: '2024-08-11 10:00:00'}, 
    //   {id: 2, title: "Deposit from viso", amount: -80, created_at: '2024-08-12 10:00:00'}
    // ]);
  }

  useEffect(() => {
    loadTransactions();
  }, [])

  const handleClick = async(transaction) => {
    navigate("/portal/transaction/detail/" + transaction.id);
  }

  return (
    <Layout menu="accounts">   
      <div className="nk-block">
        {transactions && transactions.map((x, i) => (
          <div className="row nk-odr-item" key={x.id} onClick={() => handleClick(x)}>
            {/* <div className="nk-odr-col">
              <img className="" src="/images/logo.png"
                  srcSet="/images/logo.png 2x" alt=""/>
            </div> */}
            <div className="nk-odr-col nk-odr-data">
              <div className="nk-odr-label">
                <strong className="ellipsis">
                  {x.description}
                </strong>
              </div>
              <div className="nk-odr-meta">
                <span className="date">
                  {showDateTime(x.created_at)}
                </span>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                  <div className="number-md text-s text-dark">
                      {showPrice(x.amount * x.sign, 'USD')}
                  </div>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-action">
              <div className="nk-odr-action">
                <em className="icon ni ni-forward-ios"></em>
                  {/* <a className="tnx-details" href="#" data-toggle="modal" data-target="#txn-details" onClick={(e) => {e.preventDefault(); handleClick(x)}}></a> */}
              </div>
            </div>
          </div>
        ))}

      {transactions?.length == 0 && (
        <div className="row">
          <div className="col-md-12">
            No transaction record.
          </div>
        </div>
      )}
      </div>
    </Layout>
  )
}

export default Page;
