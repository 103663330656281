import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserContext from '../../../libs/context';
import { post } from '../../../libs/api';
import { Errors } from '../../../components/common/msg';

function Page(props) {

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState();
  const [errors, setErrors] = useState([]);

  const {currentUser} = useContext(UserContext);
  const navigate = useNavigate();

  const loadAccounts = async () => {
    const data = await post('/account/list');
    if(data?.status == 200){
      setAccounts(data.data);
    }
    // setAccounts([{id: 1, account: '123456'}, {id: 2, account: "df"}]);
    // console.log('accounts');
  }

  const handleDelete = async (account) => {
    if(window.confirm('Are you sure you want to delete this account')){
      // delete the account
      const data = await post('/account/remove', {id: account.id});
      if(data?.status != 200){
        // error
        setErrors([data?.message]);
      }
      else{
        loadAccounts()
      }
    }
  }

  useEffect(() => {
    loadAccounts();
  }, [])

  return (
    <Layout menu="accounts">   
      <div className="nk-block">
        <Errors errors={errors}/>
        {accounts && accounts.map((x, i) => (
          <div className="row nk-odr-item" key={x.id}>
            <div className="nk-odr-col">
              <img className="" src="/images/logo_payermax.png"
                  srcSet="/images/logo_payermax2x.png 2x" alt=""/>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                {/* {x.name} */}
                {x.account_no}
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-action">
              <a href="#" className="link-cross mr-sm-n1" onClick={(e) => {e.preventDefault(); handleDelete(x)}}>
                <em className="icon ni ni-trash"></em>
              </a>
            </div>
          </div>
        ))}

        {accounts?.length == 0 && (
          <div>You don't have any accounts yet. You can click the following button to add an account.</div>
        )}

        <div className="row ml-2 mr-2 mt-4">
          <button className="btn btn-lg btn-primary btn-block"
            onClick={() => navigate('/portal/account/add')}>Add Account</button>
        </div>
      </div>
    </Layout>
  )
}

export default Page;
