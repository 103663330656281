import {useEffect, useState,useContext} from 'react';

import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import Layout from '../../layouts/auth';
import UserContext from "../../libs/context";
import { post } from '../../libs/api';

function Page() {
  const [notices, setNotices] = useState([]);
  const navigate = useNavigate();
  const {app} = useContext(UserContext);

  const handleAuthorize = async() => {
    const data = await post('/auth/app/authorize', {app_id: app.id, params: app.params, callback: app.callback});
    console.log('data', data);
    if(data?.status == 200){
      window.location.href = data.data.url;
    }
  }

  useEffect(() => {
    if(!app){
      navigate("/");
    }
  }, []);

  return (
    <Layout notices={notices}>

      <div className="form-group mt-2">
        <span className="highlight">{app?.name}</span> needs to acquire the following information of your ShinWallet account
        to provide related service to you: nickname, email, phone number etc.
      </div>

      <div className="form-group mt-4">
        <button className="btn btn-lg btn-primary btn-block"
          onClick={handleAuthorize}>Authorize</button>
      </div>

      <div className="form-note-s2 text-center pt-2">
        <Link to="/"><strong>Switch Account</strong></Link>
      </div>  
    </Layout>
  )
}

export default Page;
