import {useEffect, useState,useContext} from 'react';

import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import Layout from '../../layouts/auth';
import UserContext from "../../libs/context";
import { post } from '../../libs/api';
// import { Button, Toast } from 'react-weui';

function Page() {
  const [notices, setNotices] = useState([]);
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const {setApp} = useContext(UserContext);

  // const loadApp = async(appId) => {
  //   const data = await post('/auth/app/get', {app_id: appId});
  //   if(data?.status == 200){
  //     data.data.params = searchParams.get('oauth_params');
  //     data.data.callback = searchParams.get('oauth_callback');
  //     setApp(data.data);
  //   }
  // }

  // useEffect(() => {
  //   const appId = searchParams.get('appid');
  //   if(appId){
  //     loadApp(appId);
  //   }
  // }, []);

  return (
    <Layout notices={notices}>

      {/* <div className="form-group mt-2">
        <button className="btn btn-lg btn-primary btn-block"
          onClick={() => navigate('/login/email')}>Email Login</button>
      </div> */}

      <div className="form-group mt-4 mb-4">
        <button className="btn btn-lg btn-primary btn-block"
          onClick={() => navigate('/login/phone')}>Phone Login</button>

{/* <Button>hello wechat</Button>
<Toast icon="success-no-circle" show={true}>Done</Toast> */}
      </div>
    </Layout>
  )
}

export default Page;
