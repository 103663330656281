import {useState, useEffect, useRef} from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { isEmpty } from './func';
import {post} from "../../libs/api";

const Component = ({className, placeholder, options, value, onChange, onValidate, showError, required, unsupportedOptions, unsupportedOptionMsg, asyncUrl}) => {
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState();
  const [searchResults, setSearchResults] = useState([]);

  const validate = () => {
    if (!asyncUrl) {
      if (isEmpty(value)) {
        if (required) {
          return 'Required.';
        }
        return null;
      }
      if (unsupportedOptions && unsupportedOptions.includes(value)) {
        if (unsupportedOptionMsg) {
          return unsupportedOptionMsg;
        }
        return 'The selected option is not supported.';
      }
    } else {
      if (isEmpty(value.value)) {
        if (required) {
          return 'Required.';
        }
        return null;
      }
    }
    return null;
  }

  useEffect(() => {
    if (!asyncUrl) {
      let msg = validate();
      setError(msg);
      if (onValidate) {
        onValidate(msg == null);
      }
    } else {
       let msg = validate();
      setError(msg);
    }
  }, [value])

  useEffect(() => {
    if (!asyncUrl) {
      if (!options) {
        return;
      }
      for (const item of options) {
        if (item.value == value) {
          setSelected(item);
          return;
        }
      }
      setSelected(null);
    } else {
      if (value.value) {
        setSelected(value);
      }
    }
  }, [value, options]);

  const handleChange = (item) => {
    setSelected(item);
    if (!item)
      onChange({target: {value: null}});
    else
      onChange({target: {value: item.value}});
  }

  const loadOptions = async (query, callback) => {
    if (!query)
      return;
    const res = await post(asyncUrl, {query: query});
    if (res.data.length) {
      const options = res.data.map((row) => ({
        label: row.name,
        value: row.name
      }));
      setSearchResults(options);
      callback(options);
    } else {
      setSearchResults([]);
      callback([]);
    }
  };

  return (
    <div className="node-control">
      {!asyncUrl && (
      <Select 
        options={options}
        value={selected}
        onChange={handleChange}/>
      )}

      {asyncUrl && (
          <>
          <AsyncSelect
              cacheOptions
              defaultOptions
              isClearable
              value={selected}
              noOptionsMessage={() => "No Options Found"}
              loadOptions={loadOptions}
              onChange={handleChange}
              placeholder={placeholder}
          />
          </>
      )}

      {error && showError && (
        <span className="invalid">{error}</span>
      )}
    </div>
  )
}

export default Component;
