import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserContext from '../../../libs/context';
import { post } from '../../../libs/api';
import { EmailField, TextField, PasswordField, SelectField, DateField, useValidation } from '../../../components/form';
import { Errors } from '../../../components/common/msg';
import { toDate, fromDate } from '../../../libs/util';

function Page(props) {

  const [loading, setLoading] = useState(false);
  const [genderOptions, setGenderOptions] = useState();

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [birthdate, setBirthdate] = useState(null);
  const [errors, setErrors] = useState([]);

  const {currentUser, setCurrentUser} = useContext(UserContext);
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const loadOptions = async () => {
    setGenderOptions([
      {value: 'Male', label: 'Male'},
      {value: 'Female', label: 'Female'},
    ])
  }

  useEffect(() => {
    loadOptions();
  }, [])

  useEffect(() => {
    if(currentUser?.level > 0){
      navigate('/portal');
    }
  }, [currentUser]);

  const handleSubmit = async(e) => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    setLoading(true);

    // post to backend
    const input = {
      first_name: firstName, 
      middle_name: middleName, 
      last_name: lastName,
      gender: gender,
      birthdate: fromDate(birthdate), 
      address: address
    };
    const data = await post('/user/save/basic', input);
    console.log('input', input, data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else{
      // upon success
      setCurrentUser(data.data);
      navigate('/portal/account');
    }
    setLoading(false);
  }

  return (
    <Layout menu="dashboard">   
      <div className="nk-block">

        <div className="row">
          <div className="col-md-12">
            Before adding an account, you need to provide the below information. 
            No modification is allowed after submission.
            <Errors errors={errors}/>
          </div>
        </div>
        
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="first-name">First Name <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <TextField className="form-control form-control-lg" placeholder="Enter first name"
                            value={firstName} required={true} showError={showError} test={false} 
                            onValidate={(valid) => setFieldValid('firstName', valid)}
                            onChange={(e) => setFirstName(e.target.value)}/>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="middle-name">Middle Name</label>
              <div className="form-control-wrap">
                <TextField className="form-control form-control-lg" placeholder="Enter middle name"
                            value={middleName} required={false} showError={showError} test={false} 
                            onValidate={(valid) => setFieldValid('middleName', valid)}
                            onChange={(e) => setMiddleName(e.target.value)}/>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="last-name">Last Name <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <TextField className="form-control form-control-lg" placeholder="Enter last name"
                            value={lastName} required={true} showError={showError} test={false} 
                            onValidate={(valid) => setFieldValid('lastName', valid)}
                            onChange={(e) => setLastName(e.target.value)}/>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="gender">Gender <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <SelectField className="form-control form-control-lg" placeholder="Please select a gender"
                  required={true} showError={showError} onValidate={(valid) => setFieldValid('gender', valid)}
                  options={genderOptions}
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                />   
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="birthdate">Birthdate <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <DateField className="form-control form-control-lg" placeholder="Please select your birthdate"
                    required={true} showError={showError} onValidate={(valid) => setFieldValid('birthdate', valid)}
                    value={birthdate} minAge={18} minAgeMsg="You must be at least 18 years old."
                    onChange={(date) => setBirthdate(date)}
                  /> 
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="address">Address <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <TextField className="form-control form-control-lg" placeholder="Enter your address"
                            value={address} required={true} showError={showError} test={false} 
                            onValidate={(valid) => setFieldValid('address', valid)}
                            onChange={(e) => setAddress(e.target.value)}/>
              </div>
            </div>

          </div>
        </div>

        <div className="row ml-2 mr-2 mt-4">
          <button className="btn btn-lg btn-primary btn-block"
            onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </Layout>
  )
}

export default Page;
