import {useEffect, useState,useContext} from 'react';

import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import Layout from '../../layouts/auth';
import UserContext from "../../libs/context";
import { post } from '../../libs/api';

function Page() {
  const [notices, setNotices] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {app, setApp} = useContext(UserContext);

  const loadApp = async(appId) => {
    const data = await post('/auth/app/get', {app_id: appId});
    if(data?.status == 200){
      data.data.params = searchParams.get('oauth_params');
      data.data.callback = searchParams.get('oauth_callback');
      const appName = searchParams.get('app_name');
      if(appName && appName != ''){
        data.data.name = appName;
      }
      setApp(data.data);
    }
  }

  useEffect(() => {
    const appId = searchParams.get('appid');
    if(appId){
      loadApp(appId);
    }
  }, []);

  return (
    <Layout notices={notices} hideLogo={true}>

      <div className="form-group mt-2">
        <span className="highlight">{app?.name}</span> needs to acquire the following information of your ShinWallet account
        to provide related service to you: nickname, email, phone number etc.
      </div>

      <div className="form-group mt-4">
        <Link to="/" className="btn btn-lg btn-primary btn-block">Go to ShinWallet</Link>
      </div>

    </Layout>
  )
}

export default Page;
