import {useEffect, useState,useContext} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/auth';
import { Errors } from '../../components/common/msg';

import { post } from '../../libs/api';
import UserContext from "../../libs/context";
import { EmailField, TextField, PasswordField, SelectField, DateField, useValidation } from '../../components/form';

function Page() {
  const TAB_CHECK = 0;
  const TAB_REGISTER = 3;
  const TAB_PASSWORD = 2;
  const TAB_CODE = 1;

  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [tab, setTab] = useState(0);
  const [errors, setErrors] = useState([]);
  const [notices, setNotices] = useState([]);

  const navigate = useNavigate();
  const {login} = useContext(UserContext);
  
  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();

  const handleEmailCheck = async(e) => {
    if(!isValid('email')){
      setShowError(true);
      return;
    }

    setErrors([]);
    setLoading(true);
    // call backend api to send email code
    const data = await post('/auth/email/check', {email}, false);
    console.log(data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else if(data?.data) {
      setTab(TAB_PASSWORD);
    }
    else {
      setTab(TAB_REGISTER);
    }
    setLoading(false);
  }

  const handleRegister = async(e) => {
    if(!isValid(['newPassword', 'confirmPassword'])){
      setShowError(true);
      return;
    }

    if(newPassword != confirmPassword){
      setErrors(["The two passwords do not match."]);
      return;
    }

    setErrors([]);
    setLoading(true);
    // call backend api to send email code
    const data = await post('/auth/email/register', {email, password: newPassword}, false);
    console.log(data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else {
      setTab(TAB_CODE);
    }
    setLoading(false);
  }

  const handleSendCode = async(e) => {
    setErrors([]);
    setLoading(true);
    // call backend api to send email code
    const data = await post('/auth/email/code', {email}, false);
    console.log(data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    setLoading(false);
  }

  const handleLogin = async(type) => {
    console.log('handle login', type);
    if(!isValid(type)){
      setShowError(true);
      return;
    }

    setErrors([]);
    setLoading(true);
    // login with verification code
    const data = await post('/auth/email/' + type + '/login', {email, code, password}, false);
    console.log(data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else if(!data?.data?.token){
      // verify email
      setTab(TAB_CODE);
    }
    else{
      const expired = (new Date()/1000) + 86400;
      await login(data?.data.token, expired);
      navigate("/portal");
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(false);
    setErrors([]);
  }, [tab]);

  return (
    <Layout notices={notices}>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <Errors errors={errors}/>
        </div>
      </div>

      {loading ? (
          <div className="loader-container text-center">
            <ClipLoader color={'#1f2b3a'} size={50} />
              <p>Loading, please wait a moment ...</p>
          </div>
      ) : (
        <>
        {tab == TAB_CHECK && (
          <div className="is-alter">
            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label" htmlFor="username">Email Address <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <EmailField className="form-control form-control-lg" placeholder="Enter your email address"
                    value={email} required={true} showError={showError}
                    onValidate={(valid) => setFieldValid('email', valid)}
                    onChange={(e) => setEmail(e.target.value)}/>
                </div>
            </div>
            
            <div className="form-group">
              <button type="button" className="btn btn-lg btn-primary btn-block" onClick={handleEmailCheck}>Continue</button>
            </div> 
          </div>
        )}

        {tab == TAB_CODE && (
          <div className="is-alter">
            <div className="mb-2">Please check your email for the verification code.</div>
            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label" htmlFor="username">Verification Code <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <TextField className="form-control form-control-lg" placeholder="Enter verification code"
                    value={code} required={true} showError={showError}
                    onValidate={(valid) => setFieldValid('code', valid)}
                    onChange={(e) => setCode(e.target.value)}/>
                  <div className="mt-1" ><a href="#" onClick={handleSendCode}>Send Code</a></div>
                </div>
            </div>
            
            <div className="form-group">
              <button type="button" className="btn btn-lg btn-primary btn-block" onClick={(e) => handleLogin('code')}>Login</button>
            </div>
            <div className="form-note-s2 text-center">
              <a href="#" onClick={() => setTab(TAB_PASSWORD)} ><strong>Login using password</strong></a>
            </div>  
          </div>
        )}

        {tab == TAB_PASSWORD && (
          <div className="is-alter">
            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label" htmlFor="username">Password <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <PasswordField className="form-control form-control-lg" placeholder="Enter password"
                    value={password} required={true} showError={showError}
                    onValidate={(valid) => setFieldValid('password', valid)}
                    onChange={(e) => setPassword(e.target.value)}/>
                </div>
            </div>
            
            <div className="form-group">
              <button type="button" className="btn btn-lg btn-primary btn-block" onClick={(e) => handleLogin('password')}>Login</button>
            </div>
            <div className="form-note-s2 text-center">
              <a href="#" onClick={() => setTab(TAB_CODE)} ><strong>Login using email verification code</strong></a>
            </div>  
          </div>
        )}

        {tab == TAB_REGISTER && (
          <div className="is-alter">
            <div className="mb-1">Welcome {email}!</div>
            <div className="mb-2">Enter your password to create a user account.</div>

            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label" htmlFor="username">Create Password <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <PasswordField className="form-control form-control-lg" placeholder="Enter password"
                    value={newPassword} required={true} showError={showError} minLength={8}
                    onValidate={(valid) => setFieldValid('newPassword', valid)}
                    onChange={(e) => setNewPassword(e.target.value)}/>
                </div>
            </div>
            
            <div className="form-group">
                <div className="form-label-group">
                    <label className="form-label" htmlFor="username">Confirm Password <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                  <PasswordField className="form-control form-control-lg" placeholder="Enter password again"
                    value={confirmPassword} required={true} showError={showError} minLength={8}
                    onValidate={(valid) => setFieldValid('confirmPassword', valid)}
                    onChange={(e) => setConfirmPassword(e.target.value)}/>
                </div>
            </div>

            <div className="form-group">
              <button type="button" className="btn btn-lg btn-primary btn-block" onClick={handleRegister}>Create</button>
            </div>
          </div>
        )}

        <div className="form-note-s2 text-center pt-2">
          <Link to="/login"><strong>Return to login</strong></Link>
        </div>   
        </>
      )}
    </Layout>
  )
}

export default Page;
