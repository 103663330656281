import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserContext from '../../../libs/context';
import { post } from '../../../libs/api';

function Page(props) {

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();

  const {currentUser} = useContext(UserContext);
  const navigate = useNavigate();

  // const loadUser = async () => {
  //   const data = await post('/user/get');
  //   if(data?.status == 200){
  //     setUser(data.data);
  //   }
  // }

  useEffect(() => {
    console.log(currentUser)
    // loadUser();
  }, [])

  return (
    <Layout menu="setting">   
      {currentUser && (
        <div className="nk-block">
          {currentUser.email && (
          <div className="row nk-odr-item">
            <div className="nk-odr-col">
              Email
            </div>
            <div className="nk-odr-col">
              {currentUser.email}
            </div>
          </div>
          )}

          {currentUser.phone && (
          <div className="row nk-odr-item">
            <div className="nk-odr-col">
              Phone
            </div>
            <div className="nk-odr-col">
              +{currentUser.area_code} {currentUser.phone}
            </div>
          </div>
          )}

          <div className="row nk-odr-item" onClick={(e) => navigate('/portal/setting/password')}>
            <div className="nk-odr-col">
              Password
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                  <div className="number-md text-s text-dark">
                      Change
                  </div>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-action">
              <div className="nk-odr-action">
                <em className="icon ni ni-forward-ios"></em>
                  {/* <a className="tnx-details" href="#" data-toggle="modal" data-target="#txn-details" onClick={(e) => {e.preventDefault(); handleClick(x)}}></a> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Page;
