import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/auth';
import {Errors} from "../../components/common/msg";

import UserContext from "../../libs/context";
import useInterval from "../../libs/useInterval";
import { post } from '../../libs/api';

function Page(props) {
  const {maintMode, setMaintMode, logout} = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if(!maintMode){
      logout();
      setMaintMode(true);  
    }
  }, []);

  return (
      <Layout>
        <div className="nk-block-head">
          <h4 className="nk-block-title text-center">Under maintenance <br /> Thank You for Your Support</h4>
        </div>
      </Layout>
  )
}

export default Page;
