import Layout from '../../layouts/public';

function Page() {

  return (
    <Layout>
      <div className="content-page">
        <div className="nk-block-head nk-block-head-lg text-center wide-xs mx-auto">
          <div className="nk-block-head-content">
            <h2 className="nk-block-title"></h2>
          </div>
        </div>

        <div className="nk-block card card-bordered card-stretch">
          <div className="card-inner card-inner-lg">
            <article className="entry">
              This is the home page
            </article>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page;
