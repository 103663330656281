import {Link} from 'react-router-dom';
import {Notices} from "../components/common/msg";

const Layout = ({children, notices, hideLogo}) => {
    return (
        <div className="nk-body npc-cryptlite pg-auth ">
            <div className="nk-app-root">
                <div className="nk-wrap">

                  <Notices msgs={notices}/>

                    <div className="nk-block nk-block-middle nk-auth-body wide-xs">
                        {!hideLogo && (
                        <div className="brand-logo text-center mb-2 pb-4">
                            <Link className="logo-link" to="/">
                                <img className="logo-img logo-light logo-img-lg" src="/images/logo.png"
                                     srcSet="/images/logo2x.png 2x" alt=""/>
                                <img className="logo-img logo-dark logo-img-lg" src="/images/logo-dark.png"
                                     srcSet="/images/logo-dark2x.png 2x" alt=""/>
                            </Link>
                        </div>
                        )}

                        <div className="card card-bordered">
                            <div className="card-inner card-inner-lg">
                                {children}
                            </div>
                        </div>
                    </div>
                  </div>
            </div>

            {/* <div className="nk-footer nk-auth-footer-full">
                <div className="container wide-lg">
                    <div className="row g-3">
                        <div className="col-lg-6 order-lg-last">
                            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                                <li className="nav-item">
                                    <a className="nav-link" target={"_blank"} href="#">Legal Notices</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <div className="nk-block-content text-center text-lg-left">
                                <p className="text-soft">&copy;  All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};
export default Layout;