import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../layouts/user';
import UserHead from '../../components/user/head';
import {InfoAlert, WarnAlert} from '../../components/user/alert';
import UserContext from '../../libs/context';
import { post } from '../../libs/api';
import { showPrice } from '../../libs/util';

function Page(props) {

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(true);
  const [dashboard, setDashboard] = useState();
  const [balance, setBalance] = useState(0.0);

  const {currentUser} = useContext(UserContext);
  const navigate = useNavigate();

  const loadDashboard = async () => {
    const data = await post('/user/get');
    if(data?.status == 200){
      setBalance(data.data.available);
    }
  }

  useEffect(() => {
    loadDashboard();
  }, [])

  return (
    <Layout menu="dashboard">  
      <UserHead sub="Welcome!" title={currentUser?.name} description=""/>

      <div className="nk-block">
        <div className="row g-gs">
          <div className="col-md-4">
            <div className="card card-bordered card-wg on-bottom is-primary">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h5 className="nk-wgacc-title">Available Balance</h5>
                  </div>
                  <div className="card-tools">
                  </div>
                </div>
                <div className="card-amount mt-2 mb-1">
                  <span className="amount"> {showPrice(balance, 'USD')} </span>
                </div>             
              </div>
            </div>

            <button className="btn btn-lg btn-primary btn-block mt-4"
              onClick={() => navigate('/portal/transaction/withdraw')}>Cash Out</button>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page;
