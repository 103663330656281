import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserContext from '../../../libs/context';
import { post } from '../../../libs/api';
import { showDateTime, showPrice } from '../../../libs/util';

function Page(props) {

  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState();

  const {currentUser} = useContext(UserContext);
  const navigate = useNavigate();

  const loadTransaction = async () => {
    const data = await post('/transaction/detail', {id});
    console.log(data);
    if(data?.status == 200){
      setTransaction(data.data);
    }
    // setTransaction({id: 1, description: 'Withdraw to PayerMax', amount: 100, fee: 10, status: 'Completed',
    //   created_at: '2024-08-11 10:00:00', updated_at: '2024-08-11 11:00:00'
    // });
  }

  useEffect(() => {
    loadTransaction();
  }, [])

  return (
    <Layout menu="accounts">   
      <div className="nk-block">
        {transaction && (
          <>
          <div className="row nk-odr-item">
            <div className="nk-odr-col nk-odr-data">
              <div className="nk-odr-label">
                <strong className="ellipsis">
                  Amount
                </strong>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                <div className="number-md text-s text-dark">
                  {showPrice(transaction.amount * transaction.sign, 'USD')}
                </div>
              </div>
            </div>
          </div>

          <div className="row nk-odr-item">
            <div className="nk-odr-col nk-odr-data">
              <div className="nk-odr-label">
                <strong className="ellipsis">
                  Status
                </strong>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                {transaction.status}
              </div>
            </div>
          </div>

          <div className="row nk-odr-item">
            <div className="nk-odr-col nk-odr-data">
              <div className="nk-odr-label">
                <strong className="ellipsis">
                  Description
                </strong>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                {transaction.description}
              </div>
            </div>
          </div>

          <div className="row nk-odr-item mt-4">
            <div className="nk-odr-col nk-odr-data">
              <div className="nk-odr-label">
                <strong className="ellipsis">
                  Creation Date
                </strong>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                {showDateTime(transaction.created_at)}
              </div>
            </div>
          </div>

          <div className="row nk-odr-item">
            <div className="nk-odr-col nk-odr-data">
              <div className="nk-odr-label">
                <strong className="ellipsis">
                  Last Status Date
                </strong>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                {showDateTime(transaction.updated_at)}
              </div>
            </div>
          </div>

          <div className="row nk-odr-item">
            <div className="nk-odr-col nk-odr-data">
              <div className="nk-odr-label">
                <strong className="ellipsis">
                  Requested Amount
                </strong>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                <div className="number-md text-s text-dark">
                  {showPrice(transaction.amount + transaction.fee, 'USD')}
                </div>
              </div>
            </div>
          </div>

          <div className="row nk-odr-item">
            <div className="nk-odr-col nk-odr-data">
              <div className="nk-odr-label">
                <strong className="ellipsis">
                  Amount to pay
                </strong>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                <div className="number-md text-s text-dark">
                  {showPrice(transaction.amount, 'USD')}
                </div>
              </div>
            </div>
          </div>

          <div className="row nk-odr-item">
            <div className="nk-odr-col nk-odr-data">
              <div className="nk-odr-label">
                <strong className="ellipsis">
                  Fee
                </strong>
              </div>
            </div>
            <div className="nk-odr-col nk-odr-col-amount">
              <div className="nk-odr-amount">
                <div className="number-md text-s text-dark">
                  {showPrice(transaction.fee, 'USD')}
                </div>
              </div>
            </div>
          </div>

          {(transaction.type == 'Withdrawal' || transaction.type == 'Fee') && (
            <>
            <div className="row nk-odr-item mt-4">
              <div className="nk-odr-col nk-odr-data">
                <div className="nk-odr-label">
                  <strong className="ellipsis">
                    Payment Method
                  </strong>
                </div>
              </div>
              <div className="nk-odr-col nk-odr-col-amount">
                <div className="nk-odr-amount">
                  PayerMax
                </div>
              </div>
            </div>

            <div className="row nk-odr-item">
              <div className="nk-odr-col nk-odr-data">
                <div className="nk-odr-label">
                  <strong className="ellipsis">
                    Recipient Name
                  </strong>
                </div>
              </div>
              <div className="nk-odr-col nk-odr-col-amount">
                <div className="nk-odr-amount">
                  {transaction.recipient_name}
                </div>
              </div>
            </div>

            <div className="row nk-odr-item">
              <div className="nk-odr-col nk-odr-data">
                <div className="nk-odr-label">
                  <strong className="ellipsis">
                    Recipient Account
                  </strong>
                </div>
              </div>
              <div className="nk-odr-col nk-odr-col-amount">
                <div className="nk-odr-amount">
                  {transaction.recipient_account_no}
                </div>
              </div>
            </div>
            </>
          )}
          </>
        )}
      </div>
    </Layout>
  )
}

export default Page;
