import { useState, useEffect, useContext, useRef } from "react"
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { getUser, getAccounts, viewUser } from './libs/user';
import { post } from './libs/api';
import { showAmount } from "./libs/util";

import { DisplayAmount } from './components/common/display';
import UserContext from "./libs/context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { showDateTime } from "./libs/util";
import useInterval from "./libs/useInterval";
import SockJsClient from 'react-stomp';

const WS_URL = process.env.REACT_APP_API_URL + '/ws';

const Portal = ({children}) => {
  const [isLogin, setIsLogin] = useState(false);
  const [user, setUser] = useState(viewUser());
  const [menu, setMenu] = useState(null);
  const [indices, setIndices] = useState([]);
  const [time, setTime] = useState(null);
  const [title, setTitle] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const {currentUser, authStatus, logout, setNotification} = useContext(UserContext);

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

  useEffect(() => {
    if(authStatus && authStatus != 'AUTHED'){
      navigate('/login');
      return;
    }
    setIsLogin(true);
  }, [authStatus]);

  useEffect(() => {
    const path = location.pathname;
    if(path.startsWith('/portal/account')){
      setMenu('accounts');
    }
    else if(path.startsWith('/portal/transaction')){
      setMenu('transactions');
    }
    else if(path.startsWith('/portal/setting')){
      setMenu('settings');
    }
    else if(path == '/portal') {
      setMenu('dashboard');
    }
    else{
      setMenu(null);
    }

    if(path.startsWith('/portal/account/add')){
      setTitle("Add Account");
    }
    else if(path.startsWith('/portal/account/basic')){
      setTitle("Basic Level");
    }
    else if(path.startsWith('/portal/account')){
      setTitle("Accounts");
    }
    else if(path.startsWith('/portal/transaction/withdraw')){
      setTitle("Cash Out");
    }
    else if(path.startsWith('/portal/transaction/detail')){
      setTitle("Transaction Detail");
    }
    else if(path.startsWith('/portal/transaction')){
      setTitle("Transactions");
    }
    else if(path.startsWith('/portal/setting')){
      setTitle("Settings");
    }
    else {
      setTitle("");
    }

  }, [location])

  const onConnected = () => {
    // websocket connected
  }

  const onDisconnected = () => {
    // websocket disconnected
  }

  const onMessageReceived = (msg) => {
    setNotification(msg);
    alert(msg.message);
  }

  return (
    <>
    {isLogin && (
    <div className="nk-body npc-cryptlite has-sidebar has-sidebar-fat">
      {/* <SockJsClient
        url={WS_URL}
        topics={['/topic/user-' + currentUser?.id]}
        onConnect={onConnected}
        onDisconnect={onDisconnected}
        onMessage={msg => onMessageReceived(msg)}
        debug={false}
      /> */}
      <div className="nk-app-root">
        <div className="nk-main">
          <div className="nk-sidebar nk-sidebar-fat nk-sidebar-fixed is-dark" data-content="sidebarMenu">
            <div className="nk-sidebar-element nk-sidebar-head">
              <div className="nk-sidebar-brand">
                <a className="logo-link nk-sidebar-logo" href="/portal">
                  <img className="logo-img logo-light logo-img-md" src="/images/logo.png" srcSet="/images/logo2x.png 2x" alt=""/>
                  <img className="logo-img logo-dark logo-img-md" src="/images/logo-dark.png" srcSet="/images/logo-dark2x.png 2x" alt=""/>
                </a>
              </div>
              <div className="nk-menu-trigger mr-n2">
                <a href="#" className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></a>
              </div>
            </div>

            <div className="nk-sidebar-element">
              <div className="nk-sidebar-body" data-simplebar>
                <div className="nk-sidebar-content">
                  <div className="nk-sidebar-widget nk-sidebar-widget-full d-xl-none pt-0">
                    <div className="user-account-info between-center">
                      dongfeng.xing@gmail.com <br/>
                      ID: 123456
                    </div>
                  </div> 

                  <div className="nk-sidebar-menu">
                    <ul className="nk-menu">
                      <li className={menu == 'dashboard' ? "nk-menu-item active" : 'nk-menu-item'}>
                        <Link to="/portal" className="nk-menu-link nk-nav-toggle" data-target="sidebarMenu">
                          Home
                        </Link>
                      </li>

                      <li className={menu == 'accounts' ? "nk-menu-item active" : 'nk-menu-item'}>
                        <Link to="/portal/account" className="nk-menu-link nk-nav-toggle" data-target="sidebarMenu">
                          Accounts
                        </Link>
                      </li>

                      <li className={menu == 'transactions' ? "nk-menu-item active" : 'nk-menu-item'}>
                        <Link to="/portal/transaction" className="nk-menu-link nk-nav-toggle" data-target="sidebarMenu">
                          Transactions
                        </Link>
                      </li>

                      <li className={menu == 'settings' ? "nk-menu-item active" : 'nk-menu-item'}>
                        <Link to="/portal/setting" className="nk-menu-link nk-nav-toggle" data-target="sidebarMenu">
                          Settings
                        </Link>
                      </li>

                      <li className="nk-menu-item">
                        <a href="#" onClick={handleLogout} className="nk-menu-link">
                          Logout
                        </a>
                      </li>

                    </ul>
                  </div>

                  <div className="nk-sidebar-footer">
                      <ul className="nk-menu nk-menu-footer">
                        <li className="nk-menu-item">
                          {/* <a href="/page/contact-us" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-question"></em></span>
                              <span className="nk-menu-text">Contact</span>
                          </a> */}
                        </li>                                                 
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div className="nk-wrap">
            <div className="nk-header nk-header-fluid nk-header-fixed is-light">
              <div className="container-fluid">
                <div className="nk-header-wrap">
                  <div className="nk-menu-trigger d-xl-none ml-n1">
                    <a href="#" className="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></a>
                  </div>
            
                  {/* <div className="nk-header-brand d-xl-none">
                    <a className="logo-link" href="/portal">
                      <img className="logo-img logo-light" src="/images/logo.png" srcSet="/images/logo2x.png 2x" alt=""/>
                      <img className="logo-img logo-dark" src="/images/logo-dark.png" srcSet="/images/logo-dark2x.png 2x" alt=""/>
                    </a>
                  </div> */}
                  <div className="nk-header-title">{title} &nbsp;</div>
                </div>
              </div>
            </div>                        
          
            <div className="nk-content nk-content-fluid">
              <div className="container-xl wide-lg">
                <div className="nk-content-body">
                  <Outlet/>
                </div>
              </div>
            </div>

            <div className="nk-footer">
              <div className="container-fluid">
                <div className="nk-footer-wrap">
                  <div className="nk-footer-copyright">&copy; All Rights Reserved.</div>
                  <div className="nk-footer-links">
                    <ul className="nav nav-sm">
                      <li className="nav-item">
                        {/* <a className="nav-link" href="/page/privacy-policy">Legal Notices</a> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>            
          </div>          

        </div>
      </div>
    </div>
    )}
    </>
  )
}

export default Portal;