import {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Layout from '../../../layouts/user';
import UserContext from '../../../libs/context';
import { post } from '../../../libs/api';
import { showPrice } from '../../../libs/util';
import { EmailField, TextField, NumberField, PasswordField, SelectField, DateField, useValidation } from '../../../components/form';
import { Errors } from '../../../components/common/msg';

function Page(props) {

  const FEE_RATE = 0.05;

  const [loading, setLoading] = useState(false);

  const [amount, setAmount] = useState(0);
  const [fee, setFee] = useState(0);
  const [account, setAccount] = useState("");
  const [user, setUser] = useState(null);

  const [accountOptions, setAccountOptions] = useState([]);

  const {currentUser} = useContext(UserContext);
  const navigate = useNavigate();

  const [errors, setErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [setFieldValid, isValid] = useValidation();


  const loadAccounts = async() => {
    const data = await post('/account/list');
    if(data?.status == 200){
      let options = [];
      for(let account of data.data){
        options.push({value: account.id, label: account.name + " (" + account.account_no + ")"})
      }
      setAccountOptions(options);
    }
  }

  const loadUser = async() => {
    const data = await post('/user/get');
    if(data?.status == 200){
      setUser(data.data);
    }
  }

  useEffect(() => {
    loadAccounts();
    loadUser();
  }, [])

  useEffect(() => {
    setFee(1.8);
  }, [amount])

  const handleAdd = async(e) => {
    if(!isValid()){
      setShowError(true);
      return;
    }

    const amountAndFee = amount;// parseFloat(amount) + parseFloat(fee);
    console.log('amount', amount, fee, amountAndFee);
    if(amountAndFee < 10){
      setErrors(['Amount must be at least 10.']);
      return;
    }

    if(amountAndFee > user.available){
      setErrors(['Amount exceeded your account balance.']);
      return;
    }

    setLoading(true);
    
    // post to backend
    const input = {
      account_id: account, 
      amount: amount, 
    };
    const data = await post('/transaction/withdraw', input, false);
    console.log(data);
    if(data?.status != 200){
      // error
      setErrors([data?.message]);
    }
    else{
      // upon success
      navigate('/portal/transaction');
    }

    setLoading(false);
  }

  const handleAll = async(e) => {
    // const value = user.available - 1.8;// convertAmount(user.available - 1.8);
    setAmount(user.available);
  }

  const convertAmount = (amount) => {
    // return parseInt(amount * 100) / 100;
    return amount;
  }

  return (
    <Layout menu="dashboard">   
      <div className="nk-block">

        <div className="row">
          <div className="col-md-12">
            Please fill in the correct information, otherwise your withdrawal will be affected.

            <Errors errors={errors}/>
          </div>
        </div>
        
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="first-name">Recipient Account <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <SelectField className="form-control form-control-lg" placeholder="Please select recipient account"
                  required={true} showError={showError} onValidate={(valid) => setFieldValid('account', valid)}
                  options={accountOptions}
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                />   
                <div className="mt-1">Account not found? <Link to="/portal/account/add">Click here to add an account</Link></div>
              </div>
            </div>

            <div className="form-group">
              <label className="form-label" htmlFor="amount">Cash Out Amount <span className="text-danger">*</span></label>
              <div className="form-control-wrap">
                <TextField className="form-control form-control-lg" placeholder="Enter the amount to withdraw"
                            value={amount} required={true} showError={showError} test={false} 
                            onValidate={(valid) => setFieldValid('amount', valid)}
                            onChange={(e) => setAmount(convertAmount(e.target.value))}/>
                <div className="mt-1">
                  <span>Amount must be &gt;= {showPrice(10, 'USD')}. </span>
                  <a href="#" onClick={handleAll}>Withdraw All</a></div>
              </div>
            </div>

            {amount > 0 && (
            <div className="form-group">
              <div>Fee: {showPrice(fee, 'USD')}</div>
              <div className="mt-1">You will receive: {showPrice(amount - fee, 'USD')}</div>
            </div>
            )}

          </div>
        </div>

        <div className="row ml-2 mr-2 mt-4">
          <button className="btn btn-lg btn-primary btn-block"
            onClick={handleAdd}>Submit</button>
        </div>
      </div>
    </Layout>
  )
}

export default Page;
